import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/Common/Layout";
import WorkPageAbout from "../../../components/Website/WorkPageAbout";
import AllWork from "../../../components/Website/OurWork/AllWork";
import TrustUsWorkPage from "../../../components/Website/TrustUs/TrustUsWorkPage";

const Work = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);
  console.log(data);
  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="pt"
      location="work"
      title="Brocode Studio || Página de Nosso trabalho"
      description="Uma amostra do trabalho que entregamos a clientes em todo o mundo"
    >
      <WorkPageAbout selectedLanguage="pt" />
      <AllWork setModalVisible={setModalVisible} selectedLanguage="pt" />
      <TrustUsWorkPage
        setModalVisible={setModalVisible}
        selectedLanguage="pt"
      />
    </Layout>
  );
};

export default Work;

export const AllWorkQuery = graphql`
  query AllWorkPhotos {
    allFile(
      filter: {
        extension: { regex: "/(webp)/" }
        dir: { regex: "/static/img/allProjects/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
